import { Box, BoxProps, Container } from '@chakra-ui/react';
import PageHero from 'components/CMS/Heros/PageHero';
import { useRouter } from 'next/router';
import React, { ReactNode } from 'react';
import { PageQueryRes } from 'types/cms/queries';

export interface PageContainerProps extends Partial<PageQueryRes> {
  children?: ReactNode;
  containerBoxProps?: Omit<BoxProps, 'paddingX' | 'px' | 'py'>;
  containerWidth?:
    | string
    | 'container.sm'
    | 'container.md'
    | 'container.lg'
    | 'container.xl'
    | 'container.form'
    | 'container.standard'
    | 'container.xxl';
  removeHero?: boolean;
  isExtrasDashboard?: boolean;
  showHeader?: boolean;
  noPadding?: boolean;
  isAvailableExtrasBanner?: boolean;
}

const PageContainer = ({
  children,
  headerBackgroundColor,
  headerIconPattern,
  headerImage,
  headlineH1,
  headlineH1Part2,
  containerWidth,
  containerBoxProps,
  removeHero,
  isExtrasDashboard,
  showHeader,
  noPadding,
  isAvailableExtrasBanner
}: PageContainerProps): JSX.Element => {
  const { asPath } = useRouter();
  // Uses asPath to determine if it is a nested route. If true, then use slim hero.
  const useSlimHero = asPath?.split('/').filter((string) => string !== '')?.length >= 2;
  const showBanner = isAvailableExtrasBanner ? showHeader : true;
  const PageHeroProps = showBanner && headlineH1 && {
    headerBackgroundColor,
    headerIconPattern,
    headerImage,
    headlineH1,
    headlineH1Part2,
    useSlimHero
  };

  return (
    <>
      {!removeHero && PageHeroProps && (
        <PageHero isExtrasDashboard={isExtrasDashboard} {...PageHeroProps} />
      )}
      <Box
        marginY={{ base: '5', md: '9', lg: '12' }}
        paddingX={noPadding ? '0' : { base: '3', md: '7', lg: '3' }}
        {...containerBoxProps}
      >
        <Container maxW={containerWidth ?? 'container.xxl'} centerContent paddingX="0">
          {children}
        </Container>
      </Box>
    </>
  );
};

export default PageContainer;
